import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import type {
    ListingManagerFilters,
    ListingViewStyle,
    PersistedUserPreferenceValues,
    UserPreferencesFormSectionTypes
} from '@bladebinge/types';
import {
    BINGE_LISTING_PAGING_LIMIT,
    BINGE_LISTING_VIEW_STYLE_PREFERENCE,
    BINGE_THEME_PREFERENCE
} from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import { DEFAULT_USER_PREFERENCES } from '@bladebinge/web-service-common/src/constants/user-preferences';
import { useMutation } from '@tanstack/react-query';
import { nextLocalStorage } from '../../utils/next-local-storage-helper';
import { AppThemePreference, DARK_THEMES } from '../../components/MuiTheme';
import { updateUserPreferences } from '../../server/api-proxy/ui-mutation-fns/update-user-preferences';
import { useMe } from '../me/me-context';
import { usePersistedUserPreferences } from '../../hooks/react-query/logged-in-user-hooks/use-persisted-user-preferences';

interface UserPreferencesContext {
    editedSubform: UserPreferencesFormSectionTypes;
    // theme preferences
    isDarkTheme: () => boolean;
    themePreference: AppThemePreference;
    updateThemePreference: (pref: AppThemePreference) => void;

    // favorites preferences
    updateShowSoldOutItemsInFavorites: (showSoldOutItems: boolean) => void;

    // listing creation preferences
    listingManagerFilters: ListingManagerFilters;
    setUseListingAutoTagFilters: (useAutoTags: boolean) => void;
    setListingManagerFilters: (key: keyof ListingManagerFilters, value: boolean | 'ASC' | 'DESC') => void;
    useListingAutoTagFilters: boolean;

    // browsing preferences
    listingPagingLimit?: number;
    updatelistingViewStylePreference: (pref: ListingViewStyle) => void;
    updateListingPagingLimitPreference: (limit: number | null) => void;
    listingViewStylePreference: ListingViewStyle;

    // persisted user preferences
    resetPersistedUserPreferencesMutation: () => void;
    updatingPersistedUserPreferences: boolean;
    updatePersistedUserPreferencesError?: Error | null;
    updatePersistedUserPreferencesSuccess?: boolean | null;
    updatePersistedUserPreferencesValues: ({
        preferences,
        subform,
        signal
    }: {
        preferences: Partial<PersistedUserPreferenceValues>;
        subform?: UserPreferencesFormSectionTypes;
        signal?: AbortSignal;
    }) => void;
    persistedUserPreferences: PersistedUserPreferenceValues | null;
}

const userPreferencesContext = createContext<UserPreferencesContext>({
    editedSubform: null,
    // theme preferences
    isDarkTheme: () => false,
    themePreference: '',
    updateThemePreference(_pref: AppThemePreference) {},

    // favorites preferences
    updateShowSoldOutItemsInFavorites(showSoldOutItems: boolean) {},

    // listing creation preferences
    listingManagerFilters: {
        sortDirection: 'DESC',
        showActive: true,
        showCancelled: false,
        showDeactivated: true,
        showDraft: true,
        showSoldOut: false
    },
    setListingManagerFilters(key: keyof ListingManagerFilters, value: boolean | 'ASC' | 'DESC') {},
    updatelistingViewStylePreference(pref: ListingViewStyle) {},
    setUseListingAutoTagFilters(useAutoTags: boolean) {},
    useListingAutoTagFilters: false,

    // browsing preferences
    listingPagingLimit: undefined,
    listingViewStylePreference: 'list',
    updateListingPagingLimitPreference(limit: number | null) {},

    // persisted user preferences
    resetPersistedUserPreferencesMutation() {},
    updatingPersistedUserPreferences: false,
    updatePersistedUserPreferencesSuccess: null,
    async updatePersistedUserPreferencesValues({
        preferences,
        signal
    }: {
        preferences: Partial<PersistedUserPreferenceValues>;
        signal?: AbortSignal;
    }): Promise<PersistedUserPreferenceValues | { error: { message: string } }> {
        return DEFAULT_USER_PREFERENCES;
    },
    persistedUserPreferences: null
});

export const { Consumer: UserPreferencesConsumer } = userPreferencesContext;
const { Provider } = userPreferencesContext;

export const UserPreferencesProvider = ({
    children,
    themePreference: fallbackThemePreference = 'dark'
}: {
    readonly children: React.ReactNode;
    readonly themePreference?: AppThemePreference;
}) => {
    const { id: loggedInUserId } = useMe();
    const themeEffectFn = typeof window === 'undefined' ? useEffect : useLayoutEffect;
    const [themePreference, setThemePreference] = useState<AppThemePreference>('dark');

    const [editedSubform, setEditedSubform] = useState<UserPreferencesFormSectionTypes>(null);
    const [isListingAutoTaggingEnabled, setIsListingAutoTaggingEnabled] = useState<boolean>(false);
    const [listingPagingLimit, setListingPagingLimit] = useState<number | undefined>(undefined);
    const [listingViewStylePreference, setListingViewStylePreference] = useState<ListingViewStyle>('list');
    const [listingManagerFilterChoices, setListingManagerFilterChoices] = useState<ListingManagerFilters>({
        sortDirection: 'DESC',
        showActive: true,
        showCancelled: false,
        showDeactivated: true,
        showDraft: true,
        showSoldOut: false
    });

    const [persistedUserPreferences, setPersistedUserPreferences] = useState<PersistedUserPreferenceValues | null>(
        DEFAULT_USER_PREFERENCES
    );

    const updateUserPreferencesMutation = useMutation({
        mutationFn: updateUserPreferences,
        onSuccess(updatedPreferences, variables) {
            const { subform = null } = variables;
            setEditedSubform(subform as UserPreferencesFormSectionTypes);
            setListingViewStylePreference(updatedPreferences.browsingPreferences.listingViewStylePreference ?? 'list');
            if (updatedPreferences.browsingPreferences.listingViewStylePreference) {
                nextLocalStorage.set(
                    BINGE_LISTING_VIEW_STYLE_PREFERENCE,
                    updatedPreferences.browsingPreferences.listingViewStylePreference
                );
            }

            setListingPagingLimit(updatedPreferences.browsingPreferences.listingPagingLimit ?? 25);
            if (updatedPreferences.browsingPreferences.listingPagingLimit) {
                nextLocalStorage.set(
                    BINGE_LISTING_PAGING_LIMIT,
                    `${updatedPreferences.browsingPreferences.listingPagingLimit}`
                );
            }

            setPersistedUserPreferences(updatedPreferences);
        }
    });

    // UPDATING USER PERSISTED PREFERENCES
    const {
        error: updatePersistedUserPreferencesError,
        isPending: updatingPersistedUserPreferences,
        isSuccess: updatePersistedUserPreferencesSuccess,
        reset: resetPersistedUserPreferencesMutation
    } = updateUserPreferencesMutation;

    // FETCHING USER PERSISTED PREFERENCES
    const { data: fetchedUserPreferencesData, refetch: refetchUserPreferences } = usePersistedUserPreferences({
        userId: loggedInUserId
    });

    useEffect(() => {
        setPersistedUserPreferences(fetchedUserPreferencesData ?? DEFAULT_USER_PREFERENCES);
    }, [fetchedUserPreferencesData]);

    useEffect(() => {
        if (loggedInUserId) {
            refetchUserPreferences();
        } else {
            setPersistedUserPreferences(DEFAULT_USER_PREFERENCES);
        }
    }, [loggedInUserId, refetchUserPreferences]);

    /* eslint-disable react-hooks/exhaustive-deps */
    // on mount update from local storage
    themeEffectFn(() => {
        const localThemePreference = nextLocalStorage.get(BINGE_THEME_PREFERENCE);
        if (localThemePreference) {
            setThemePreference((localThemePreference ?? fallbackThemePreference) as AppThemePreference);
        }

        const localListingFiltersPreference = nextLocalStorage.get('listingManagerFilters');
        if (localListingFiltersPreference) {
            setListingManagerFilterChoices(JSON.parse(localListingFiltersPreference));
        }

        const localListingAutoTaggingPreference = nextLocalStorage.get('useListingAutoTagFilters');
        if (localListingAutoTaggingPreference) {
            setIsListingAutoTaggingEnabled(localListingAutoTaggingPreference === 'true');
        }

        const localPagingLimitPreference = nextLocalStorage.get(BINGE_LISTING_PAGING_LIMIT);
        if (localPagingLimitPreference) {
            setListingPagingLimit(parseInt(localPagingLimitPreference, 10));
        }

        const locallistingViewStylePreference = nextLocalStorage.get(BINGE_LISTING_VIEW_STYLE_PREFERENCE);
        if (['grid', 'list'].includes(locallistingViewStylePreference as string)) {
            setListingViewStylePreference(locallistingViewStylePreference as ListingViewStyle);
        }
    }, []);

    // Dark is default theme
    const isDarkTheme = useMemo(
        () => () => !themePreference || DARK_THEMES.includes(themePreference),
        [themePreference]
    );

    const setListingManagerFilters = useCallback(
        (key: keyof ListingManagerFilters, value: boolean | 'ASC' | 'DESC') => {
            const updatedPrefs = {
                ...listingManagerFilterChoices,
                [key]: value
            };
            setListingManagerFilterChoices(updatedPrefs);
            nextLocalStorage.set('listingManagerFilters', JSON.stringify(updatedPrefs));
        },
        [listingManagerFilterChoices, setListingManagerFilterChoices]
    );

    const setUseListingAutoTagFilters = useCallback(
        (useAutoTags: boolean) => {
            setIsListingAutoTaggingEnabled(useAutoTags);
            nextLocalStorage.set('useListingAutoTagFilters', `${useAutoTags}`);
        },
        [isListingAutoTaggingEnabled, setIsListingAutoTaggingEnabled]
    );

    const updateThemePreference = useCallback(
        (pref: AppThemePreference) => {
            if (pref) {
                nextLocalStorage.set(BINGE_THEME_PREFERENCE, pref);
            }

            setThemePreference(pref);
        },
        [setThemePreference]
    );

    const updatePersistedUserPreferencesValues = useCallback(
        ({
            preferences,
            subform = null,
            signal
        }: {
            preferences: Partial<PersistedUserPreferenceValues>;
            subform?: UserPreferencesFormSectionTypes;
            signal?: AbortSignal;
        }) => {
            if (!loggedInUserId) {
                return;
            }

            updateUserPreferencesMutation.mutate({
                userId: loggedInUserId,
                preferences,
                signal,
                subform
            });
        },
        [loggedInUserId, updateUserPreferencesMutation]
    );

    const updateListingPagingLimitPreference = useCallback(
        (limit: number | null) => {
            if (limit) {
                nextLocalStorage.set(BINGE_LISTING_PAGING_LIMIT, `${limit}`);
                setListingPagingLimit(limit);
            }

            // setListingPagingLimit(limit ?? 25);
            updatePersistedUserPreferencesValues({
                preferences: {
                    browsingPreferences: {
                        listingPagingLimit: limit ?? 25,
                        listingViewStylePreference
                    }
                }
            });
        },
        [setListingPagingLimit]
    );

    const updateShowSoldOutItemsInFavorites = (showSoldOutItems: boolean) => {
        updatePersistedUserPreferencesValues({
            preferences: {
                favorites: {
                    ...persistedUserPreferences?.favorites,
                    showSoldOutItems
                }
            }
        });
    };

    const updatelistingViewStylePreference = (pref: ListingViewStyle) => {
        if (['grid', 'list'].includes(pref)) {
            nextLocalStorage.set(BINGE_LISTING_VIEW_STYLE_PREFERENCE, pref);
        }

        setListingViewStylePreference(pref);

        updatePersistedUserPreferencesValues({
            preferences: {
                browsingPreferences: {
                    listingPagingLimit: listingPagingLimit ?? 25,
                    listingViewStylePreference: pref
                }
            }
        });
    };

    return (
        <Provider
            value={{
                editedSubform,

                // favorites
                updateShowSoldOutItemsInFavorites,

                // Theming
                themePreference,
                isDarkTheme,
                updateThemePreference,

                // UI preferences
                listingManagerFilters: listingManagerFilterChoices,
                listingPagingLimit,
                listingViewStylePreference,
                updatelistingViewStylePreference,
                setListingManagerFilters,
                useListingAutoTagFilters: isListingAutoTaggingEnabled,
                setUseListingAutoTagFilters,
                updateListingPagingLimitPreference,

                resetPersistedUserPreferencesMutation,
                updatingPersistedUserPreferences,
                updatePersistedUserPreferencesError,
                updatePersistedUserPreferencesSuccess,
                updatePersistedUserPreferencesValues,
                persistedUserPreferences
            }}
        >
            {children}
        </Provider>
    );
};

export const useUserPreferences = () => useContext(userPreferencesContext);
